/*
 * @Author: your name
 * @Date: 2020-02-07 15:11:47
 * @LastEditTime: 2021-09-13 16:05:11
 * @LastEditors: SongXiao
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\util\common.js
 */
import moment from 'moment';
export const commonFunc = {
    // 
    scrollToTop(){
        document.getElementById('root').scrollTop = 0;
    },
    appScrollToTop(){
        document.documentElement.scrollTop=0;

    },
    getNowStartTime(time){
        let yyyy = time.getFullYear();
        let MM = (time.getMonth()+1)>9?(time.getMonth()+1):`0${(time.getMonth()+1)}`;
        let dd = (time.getDate())>9?(time.getDate()):`0${(time.getDate())}`;
        let hh = (time.getHours())>9?(time.getHours()):`0${(time.getHours())}`;
        let mm = (time.getMinutes())>9?(time.getMinutes()):`0${(time.getMinutes())}`;
        let ss = (time.getSeconds())>9?(time.getSeconds()):`0${(time.getSeconds())}`;
        return `${yyyy}/${MM}/${dd} ${hh}:${mm}:${ss}`;
    },
    // 2020-02-14 06:05:03
    dateTimeStamp(time){
        // return new Date(moment(time, "YYYY-MM-DD HH:mm:ss")._d).getTime();
        return moment(time).valueOf();
    },
  
    // 今天内：n前、昨天、前天、 前天以前：具体时间 
    timeago(dateTimeStamp){  
        var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
        var hour = minute * 60;
        var day = hour * 24;
        var week = day * 7;
        var halfamonth = day * 15;
        var month = day * 30;

        var now = new Date().getTime();   //获取当前时间毫秒
        var result = '';
        var diffValue = now - dateTimeStamp;//时间差
        if(diffValue > 3*24*60*60*1000){
            return moment(dateTimeStamp).format('YYYY-MM-DD');
        }
        var minC = diffValue/minute;  // 计算时间差的分，时，天，周，月
        var hourC = diffValue/hour;
        var dayC = diffValue/day;
        var weekC = diffValue/week;
        var monthC = diffValue/month;
        
        if(monthC >= 1 && monthC <= 6){
            result = " " + parseInt(monthC) + "月前"
        }else if(weekC >= 1 && weekC <= 4){
            result = " " + parseInt(weekC) + "周前"
        }else if(dayC >= 1 && dayC <= 7){
            if(dayC <= 2){
                result = '昨天'
            }else if(dayC >= 2&& dayC <= 3){
                result = '前天'
            }
            // result = " " + parseInt(dayC) + "天前"
        }else if(hourC >= 1 && hourC <= 24){
            result = " " + parseInt(hourC) + "小时前"
        }else if(minC >= 1 && minC <= 60){
            result =" " + parseInt(minC) + "分钟前"
        }else if( diffValue <= minute){
            result = "刚刚"
        }else {
            return moment(dateTimeStamp).format('YYYY-MM-DD');
        }
        return result;
    },
    // 今天的特殊显示：一小时以内，显示xx分钟前，大于一小时，显示xx小时前;1分钟内，刚刚； 昨天+时分秒 前天+时分秒 具体日期+时分秒
    timeAgo(dateTimeStamp){
        var minute = 1000 * 60;      //把分，时，天毫秒表示
        var hour = minute * 60;
        var day = hour * 24;

        var now = new Date().getTime();   //获取当前时间毫秒
        var result = '';
        var diffValue = now - dateTimeStamp;//时间差
        if(diffValue > 3*24*60*60*1000){ // 3天以前
            return moment(dateTimeStamp).format('YYYY-MM-DD HH:mm:ss');
        }
        var minC = diffValue/minute;  // 计算时间差的分，时，天，周，月
        var hourC = diffValue/hour;
        var dayC = diffValue/day;
        // 昨天、前天
        if(dayC >= 1 && dayC <= 3){
            if(dayC <= 2){
                result = '昨天' + moment(dateTimeStamp).format(' HH:mm:ss')
            }else if(dayC >= 2&& dayC <= 3){
                result = '前天' + moment(dateTimeStamp).format(' HH:mm:ss')
            }
        }else if(dayC <1 ){ // 今天
            if(hourC >= 1 && hourC <= 24){
                result = " " + parseInt(hourC) + "小时前"
            }else if(minC >= 1 && minC <= 60){
                result =" " + parseInt(minC) + "分钟前"
            }else if( diffValue <= minute){
                result = "刚刚"
            }
        }else {
            return moment(dateTimeStamp).format('YYYY-MM-DD HH:mm:ss');
        }        
        return result;
    }
}

export const formatHTML = function(content){
    let a = (content || "").replace(/\[p([^\]]*)\]/g,function(a,b){
        return `<p ${b}>`;
    }).replace(/\[\/p\]/g,function(a,b){
        return '</p>';
    })
    .replace(/\[a([^\]]*)\]/g,function(a,b){ // [a href="http://www.scooper.com.cn"] 叙简科技 [/a]
        return `<a ${b}>`
    }).replace(/\[\/a\]/g,function(a,b){
        return '</a>';
    })
    .replace(/\[img\]([^\[]+)/g,function(a,b){ // [img]image/news/2020/05/13/c1ceb4ce-9524-11ea-b651-00163e158638.jpeg[/img]
        return `<div class='pic-wrapper'><img src='${/^http/.test(b)?b:'/'+b}'/></div>` 
        // return `<div class='pic-wrapper'><img src='${/^http/.test(b)?b:'file://192.168.130.190/icooper/vision_dev/huchang/yjkp_img/'+b}'/></div>` 
    }).replace(/\[\/img\]/g,function(a,b){
        return '';
    })
    .replace(/\[table([^\]]*)\]/g,function(a,b){ // [table  align="center"  width="750"  border="1"  cellspacing="0"  cellpadding="0"][/table] 
        return `<table ${b}>`
    }).replace(/\[\/table\]/g,function(a,b){
        return '</table>';
    })
    .replace(/\[tr\]/g,function(a,b){ //[tr][/tr]
        return `<tr>`
    }).replace(/\[\/tr\]/g,function(a,b){
        return '</tr>';
    })
    .replace(/\[td([^\]]*)\]/g,function(a,b){
        return `<td ${b}>`;
    }).replace(/\[\/td\]/g,function(a,b){
        return '</td>';
    })
    .replace(/\[strong\]/g,function(a,b){ //[tr][/tr]
        return `<strong>`
    }).replace(/\[\/strong\]/g,function(a,b){
        return '</strong>';
    })
    .replace(/\[video\]([^\[]+)/g,function(a,b){
        return `<video src='${b}' controls />`
    }).replace(/\[\/video\]/g,function(a,b){
        return '';
    });
    return a
}