/*
 * @Author: hexingxing
 * @Date: 2020-02-05 11:07:14
 * @LastEditTime: 2021-09-15 11:57:30
 * @LastEditors: SongXiao
 * @Description: 新闻内容页-分享版
 * @FilePath: \react-app\src\view\index\index.js
 */


import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import NewsList from '../../component/news-list';
import Article from './article';
import { apis } from "../../util/apis";
import { connect } from "react-redux";
import { LaunchApp,  isAndroid,  inWeixin } from 'web-launch-app';

import { Icon, Spin } from 'antd';
import { Toast } from 'antd-mobile';
@connect(
    state => state.news
)
@withRouter
class NewsContentWeb extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shouldLeftMenuFixed: false,
            newsData: {},
            relatedNewsList: [],
            newestNewsList: [],
            firstImgUrl: null,// 文章中的第1张图片地址
            loadingOpenApp: false,// 打开app的loading
            relateList: [],//聚合报道
            aggregationList: [],// 相关事件

            total: 0,
            relyList: [],
            hasNextPage: false,
            newContentLoading: false,// 页面内容loading
        }
    }
    componentDidMount = () => {
        // document.getElementById('root').addEventListener('scroll', this.setLeftMenuClass);
        this.getNewsContent();
        this.getCount();
        this.getRelatedNewsList();
        this.listLatestNews();

        this.queryRelate();
        this.pageAggregation();
        document.getElementById('phone-news-content').addEventListener('scroll', this.onRootScroll);
    }
    componentWillUnmount = () => {
        // document.getElementById('root').removeEventListener('scroll', this.setLeftMenuClass);
        document.getElementById('phone-news-content').removeEventListener('scroll', this.onRootScroll);
    }
    componentWillReceiveProps = (nextProps) => {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            // document.getElementById('root').addEventListener('scroll', this.setLeftMenuClass);
            this.getNewsContent(nextProps.match.params.id);
            this.getCount();
            // this.getRelatedNewsList(this.props.match.params.id);
            this.queryRelate(nextProps.match.params.id);
            this.pageAggregation(nextProps.match.params.id);
            if (this.props.isPhone) {
                document.getElementById('phone-news-content').scrollTop = 0;
            }
        }
    }
    onRootScroll = (e) => {
        const scrollEle = document.getElementById('phone-news-content');
        if (scrollEle.scrollHeight - scrollEle.scrollTop - 10 <= scrollEle.clientHeight && this.state.hasNextPage) {
            scrollEle.removeEventListener('scroll', this.onRootScroll);
            this.pageNewsReply(this.state.pageNum + 1);
        }
    }
    getNewsContent = async (id) => {
        this.setState({ newContentLoading: true })

        const dataRes = await apis.project.getNewsContent({ newsId: id || (this.props.match.params.id) }) || {};
        let firstImgUrl = null;
        let c = dataRes.newsContent || '';
        var as = c.replace(/\[img\]([^\[]+)/, function (a, b) {
            firstImgUrl = `${b}`;
            return ''
        })
        this.setState({ newContentLoading:false, newsData: dataRes, firstImgUrl },()=>{
            this.pageNewsReply(1);
        })
    }
    getCount = async () => {
        const dataRes = await apis.project.count({ newsId: this.props.match.params.id }) || {};
    }
    // 相关咨询
    getRelatedNewsList = async (id) => {
        let params = { pageNum: 1, pageSize: 10, classifyKey: this.props.match.params.classify, newsId: id || this.props.match.params.id };
        if (params.classifyKey === 'city') {
            params.classifyKey = '';
            params.areaCode = '330100000000';
        }
        // const dataRes = await apis.project.pageNewsList(params) || {};
        // this.setState({ relatedNewsList: dataRes.list })
    }
    // 聚合报道
    queryRelate = async (id) => {
        let params = { newsId: id || this.props.match.params.id };
        const dataRes = await apis.project.queryRelate(params) || {};
        this.setState({ relateList: dataRes.list||[] })
    }
    // 相关事件
    pageAggregation = async (id) => {
        let params = { pageNum: 1, pageSize: 10, newsId: id || this.props.match.params.id };
        const dataRes = await apis.project.pageAggregation(params) || {};
        this.setState({ aggregationList: dataRes.list||[] })
    }
    // // 设置左侧menu的class
    // setLeftMenuClass = () => {
    //     if (document.getElementById('left-content-wrapper') && document.getElementById('left-content-wrapper').getBoundingClientRect().top <= 0) {
    //         this.setState({
    //             shouldLeftMenuFixed: true
    //         })
    //     } else {
    //         this.setState({
    //             shouldLeftMenuFixed: false
    //         })
    //     }
    // }
    listLatestNews = async () => {
        const dataRes = await apis.project.listLatest({ pageSize: 10 }) || {};

        this.setState({ newestNewsList: dataRes })
    }

    // 获取新闻评论列表
    pageNewsReply = async (pageNum) => {
        let params = { pageNum: pageNum || 1, relateId: this.state.newsData.relateId, pageSize: 10 };
        const pageNewsReply = await apis.project.pageNewsReply(params) || {};
        let list = [];
        if (pageNum > 1) {
            list = [...this.state.relyList, ...pageNewsReply.list]
        } else {
            list = pageNewsReply.list;
        }
        this.setState({
            relyList: list,
            pageNum: pageNewsReply.pageNum,
            hasNextPage: pageNewsReply.hasNextPage,
            total: pageNewsReply.total
        }, () => {
            document.getElementById('phone-news-content').addEventListener('scroll', this.onRootScroll);
        })
    }
    openInapp = () => {
        //  ios
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
            const lanchApp = new LaunchApp();
            lanchApp.open({
                useYingyongbao: inWeixin && isAndroid,
                launchType: {
                    ios: 'link', // link
                    android: inWeixin ? 'scheme' : 'store',
                },
                url: 'https://image.e-subao.com',
                timeout: 2000,
            },(s,d,url)=>{
                return 1;
            });       
        } else if (navigator.userAgent.match(/(android|Android);?/i)) {
            if(inWeixin){
                Toast.info("请在浏览器中打开该页面")
                return
            }
            window.location.href = "esubaoapp://e-subao.com/detail/"+this.props.match.params.classify+"/"+this.props.match.params.id;
            window.setTimeout(function () {
                window.location.href = '/download';
            }, 2000);
        }
    }
    render() {
        let { total, newsData, hasNextPage, relyList, relateList, aggregationList,
            newContentLoading } = this.state;
        return (
            <div className='page-content'>
                <div id='news-content-share ' className="news-detail-page-wrapper content-center top-fixed clearfix content-center-new-content content-center-new-content-share">
                    <div className="content-wrapper">
                        <div className='news-detail-page'>
                            <div className="left-mod" id='phone-news-content'>
                                <div className="article-wrapper">
                                    <Spin spinning={newContentLoading} indicator={<Icon type="loading" style={{ fontSize: 24, color: '#FF842C' }} spin />}> 
                                        <Article newsData={newsData} /> 
                                    </Spin>
                                </div>
                                <div className='divide'></div>

                                {relateList.length > 0 && !newContentLoading&&<div className="news-list-wrapper">
                                    <h3><Icon type="profile" style={{ color: '#F8802B', marginRight: '6px' }} />聚合报道</h3>
                                    <NewsList isSharePage={true} classifyKey={this.props.match.params.classify} fnewsList={relateList} />
                                </div>}
                                {aggregationList.length > 0 && !newContentLoading&&<div className="news-list-wrapper">
                                    <h3><Icon type="block" style={{ color: '#F8802B', marginRight: '6px' }} />相关事件</h3>
                                    <NewsList isSharePage={true} classifyKey={this.props.match.params.classify} fnewsList={aggregationList} />
                                </div>}
                                {/* 评论列表 */}
                                <div className='comment-list-wrapper'>
                                    {!newContentLoading&&<h5 className='comment-title'>
                                        {`${total || '0'}条评论`}
                                    </h5>}
                                    <div className='comment-list'>
                                        {(relyList || []).map((item, index) => (
                                            <div className='comment-item' key={'comment' + index}>
                                                <span className='item-avaror'>
                                                    <img src={(item.account||{}).avatar} alt="" />
                                                </span>
                                                <div className='comment-item-cb'>
                                                    <div className='cb-head'><span className='name'>{(item.account||{}).nickname}</span></div>
                                                    <div className='cb-txt'>{item.content}</div>
                                                    <div className='cb-footer'><span className='time'>{item.gmtCreate}</span></div>
                                                </div>
                                            </div>
                                        ))}
                                        {hasNextPage &&!newContentLoading&& <div className='load-more-btn'><Spin spinning={true} /></div>}
                                        {!hasNextPage &&!newContentLoading&& <div className='no-more-text'>没有更多</div>}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="content-inner-right-wrapper right-mod">
                                <div className='newest-news-box'>
                                    <h2 className='common-news-title'><span className='main-text'>最新快讯</span><span className='sub-text'>NEWS</span></h2>
                                    <NewestNewsList classifyKey={this.props.match.params.classify} list={this.state.newestNewsList || []} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="open-in-app-box">
                    <div className="left-logo">
                        <span className='logo-img'></span>
                        <span className='logo-text-img'></span>
                    </div>
                    <div className='right-open-inapp-btn-wrapper'>
                        <span className='open-inapp-btn' onClick={this.openInapp}>打开APP</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsContentWeb;