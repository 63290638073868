/*
 * @Author: your name
 * @Date: 2020-02-05 11:59:53
 * @LastEditTime: 2021-06-03 15:26:03
 * @LastEditors: Please set LastEditors
 * @Description: 快讯中的-最新资讯item（24小时快报）
 */

import React, { PureComponent } from "react";
import { Link, withRouter } from 'react-router-dom';
import {commonFunc} from '../util/common';
// import bgImage from '../image/login/login-code-logo.jpg';
@withRouter
class NewestNewsItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hasImg:true
        }
    }

    render() {
        // const url = this.props.match.url;
        // const curPath = this.props.location.pathname;
        let { newsData } = this.props;
        return (
            <div className='newest-news-item'>               
                {/* <h3 className='title'>{newsData.newsTitle}</h3> */}
                <h3 className='title'>
                    <Link to={`/detail/${this.props.classifyKey}/${newsData.id}`} 
                        target={`${this.props.isPhone?'_self':'_blank'}`}
                    >{newsData.newsTitle}</Link>
                </h3>
                <p className='details'><span>{commonFunc.timeago(commonFunc.dateTimeStamp(newsData.newsTime))}</span></p>
            </div>
        );
    }
}

export default NewestNewsItem;
