/**
 * @file 事件模块 reducer
 * @author XXX
 * @date 2019/10/10
 * @version:v1.1.0
 */

const EVENT_ADD_NUMBER = 'EVENT_ADD_NUMBER';
const EVENT_REDUCE_NUMBER = 'EVENT_REDUCE_NUMBER';

const initState = {
    eventNumber : 0
};

export function eventHandleReducer(state=initState,action) {
    switch (action.type){
        case EVENT_ADD_NUMBER: return {...state, eventNumber : state.eventNumber+1};
        case EVENT_REDUCE_NUMBER: return {...state, eventNumber : state.eventNumber-1};
        default: return state;
    }
}

export function addEventNumber() {
    return {type: EVENT_ADD_NUMBER};
}

export function reduceEventNumber() {
    return {type: EVENT_REDUCE_NUMBER};
}

export  function addGunAsync() {
    return dispatch =>{
        setTimeout(()=>{
            dispatch(addEventNumber())
        }, 2000)
    }
}

