/*
 * @Author: hexingxing
 * @Date: 2020-02-05 11:07:14
 * @LastEditTime: 2021-06-03 13:48:54
 * @LastEditors: Please set LastEditors
 * @Description: 新闻内容页 
 * @FilePath: \react-app\src\view\index\index.js
 */
import React, { PureComponent } from "react";
import { Link, withRouter } from 'react-router-dom';
import NewsList from '../../component/news-list';
import Article from './article';
// import BaikeList from '../../component/baike-list';
import NewestNewsList from '../../component/newest-news-list';
import Share from '../../component/share'
import { apis } from "../../util/apis";
import { connect } from "react-redux";
// import SysHeader from '../../component/sys-header';
import { HOME_PAGE } from '../../config/constants';
import { TextareaItem, Badge, Toast, ActionSheet } from 'antd-mobile';
import shareWxPyq from '../../image/wx_pyq.png';
import shareWxHy from '../../image/wx_hy.png';
import shareDD from '../../image/dd.png';

import { Spin } from 'antd';
import qs from 'qs';
@connect(
    state => state.news,
)
@withRouter
class NewsContent extends PureComponent {
    constructor(props) {
        super(props);
        let searchObj = qs.parse(window.location.search.substr(1))||{}; 
        if(searchObj.token=='undefined'){ // 从详情的新闻列表进入详情，拼接时token若为undefined,typeof 结果是字符串
            searchObj.token=undefined;
        }
        this.isH5Shell = searchObj.isH5Shell;
        this.searchObj = searchObj;
        this.state = {
            shouldLeftMenuFixed: false,
            newsData: {},
            keywordsList:[],
            relatedNewsList: [],
            newestNewsList: [],
            firstImgUrl: null,// 文章中的第1张图片地址
            firstText: '',// 文章第一段
            relateList: [],// 聚合报道
            aggregationList: [],// 相关事件
            hasNextPage:false,

            iptFocus: false,
            iptValue: '',
            total: 0,
            relyList:[],
            newContentLoading: false,// 页面内容loading
            relateListLoading: false,//聚合报道loading
            aggregationListLoading: false,// 相关事件loading
            scrollToRelay: true,// 是否下次点击写评论图标滚动到评论
            // relayPosiTop: 0,
            lastPosition: 0,
        }
    }
    componentDidMount = () => {
        document.getElementById('root').addEventListener('scroll', this.setLeftMenuClass);
        this.getNewsContent();
        this.getCount();
        this.getRelatedNewsList();
        this.listLatestNews();

        this.queryRelate();
        this.pageAggregation();
        document.getElementById('phone-news-content').addEventListener('scroll', this.onRootScroll);
        
        // 用于点击原生按钮 - 滚动到评论所在的box
        window.scrollToReply = () => {
            let { newContentLoading, aggregationListLoading, relateListLoading } = this.state;
            if(newContentLoading||aggregationListLoading||relateListLoading){
                return
            }
            this.setState({scrollToRelay: false}); // 下次点击评论 不去评论
            document.getElementById('phone-news-content').scrollTo({ top: document.getElementById('comment-list-wrapper').offsetTop ,behavior:'smooth' })
        }
    }
    componentWillUpdate = () => {
        // let t = document.getElementById('comment-list-wrapper').offsetTop;
        // this.setState({relayPosiTop: t })
        // console.log('ud',t);
    }
    componentWillUnmount = () => {
        document.getElementById('root').removeEventListener('scroll', this.setLeftMenuClass);
        document.getElementById('phone-news-content').removeEventListener('scroll', this.onRootScroll);
    }
    componentWillReceiveProps = (nextProps) => {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            document.getElementById('root').addEventListener('scroll', this.setLeftMenuClass);
            this.getNewsContent(nextProps.match.params.id);
            this.getCount();
            // this.getRelatedNewsList(nextProps.match.params.id);
            this.queryRelate(nextProps.match.params.id);
            this.pageAggregation(nextProps.match.params.id);
            if (this.props.isPhone) {
                document.getElementById('phone-news-content').scrollTop = 0;
            }
        }
    }
    onRootScroll=(e)=>{
        const scrollEle = document.getElementById('phone-news-content');
        if(scrollEle.scrollHeight - scrollEle.scrollTop - 10<= scrollEle.clientHeight&&this.state.hasNextPage){
            scrollEle.removeEventListener('scroll', this.onRootScroll);
            this.pageNewsReply(this.state.pageNum+1);
        }
    }
    // 获取新闻内容
    getNewsContent = async (id) => {
        this.setState({ newContentLoading: true })
        let tokenFromUrl = this.searchObj.token;
        const dataRes = await apis.project.getNewsContent({ newsId: id || (this.props.match.params.id), token: tokenFromUrl }) || {};
        let firstImgUrl = null;
        let firstText = null;
        let c = dataRes.newsContent || '';
        c.replace(/\[p\]([^\[]+)/, function (a, b) {
            firstText = `${b}`;
            return ''
        })
        c.replace(/\[img\]([^\[]+)/, function (a, b) {
            firstImgUrl = `${b}`;
            return ''
        });
        this.isH5Shell&&window.open('scooper://stopLoadingAnim')
        this.setState({ newContentLoading:false, newsData: dataRes, keywordsList: (dataRes.keywords||'').split(','), doCollectSuccess: dataRes.status, firstImgUrl, firstText },()=>{
            this.pageNewsReply(1);
        })
    }
    // 统计
    getCount = async () => {
        await apis.project.count({ newsId: this.props.match.params.id });
    }
    // 相关咨询
    getRelatedNewsList = async (id) => {
        let params = { pageNum: 1, pageSize: 10, classifyKey: this.props.match.params.classify, newsId: id || this.props.match.params.id };
        // if (params.classifyKey === 'hangzhou') {
        if (params.classifyKey === 'city') {
            params.classifyKey = '';
            params.areaCode = '330100000000';
        }
        // const dataRes = await apis.project.pageNewsList(params) || {};
        // this.setState({ relatedNewsList: dataRes.list })
    }
    // 聚合报道
    queryRelate = async (id) => {
        this.setState({relateListLoading: true})
        let params = { newsId: id || this.props.match.params.id };
        const dataRes = await apis.project.queryRelate(params) || {};
        this.setState({ relateList: dataRes.list, relateListLoading: false });
    }
    // 相关事件
    pageAggregation = async (id) => {
        this.setState({aggregationListLoading: true})
        let params = { pageNum: 1, pageSize: 10, newsId: id || this.props.match.params.id };
        const dataRes = await apis.project.pageAggregation(params) || {};
        this.setState({ aggregationList: dataRes.list, aggregationListLoading: false })
    }
    // 设置左侧menu的class
    setLeftMenuClass = () => {
        if (document.getElementById('left-content-wrapper').getBoundingClientRect().top <= 0) {
            this.setState({
                shouldLeftMenuFixed: true
            })
        } else {
            this.setState({
                shouldLeftMenuFixed: false
            })
        }
    }
    listLatestNews = async () => {
        const dataRes = await apis.project.listLatest({ pageSize: 10 }) || [];
        this.setState({ newestNewsList: dataRes })
    }
    // 获取新闻列表
    pageNewsReply=async(pageNum)=>{
        let tokenFromUrl = this.searchObj.token;
        let params = { pageNum: pageNum || 1, relateId: this.state.newsData.relateId, token: tokenFromUrl,pageSize: 10 };
        const pageNewsReply = await apis.project.pageNewsReply(params) || {};
        let list=[];
        if(pageNum>1){
            list=[...this.state.relyList,...pageNewsReply.list]
        }else{
            list=pageNewsReply.list;
        }
        this.setState({
            relyList:list,
            pageNum:pageNewsReply.pageNum,
            hasNextPage:pageNewsReply.hasNextPage,
            total:pageNewsReply.total
        },()=>{
            document.getElementById('phone-news-content').addEventListener('scroll', this.onRootScroll);
        })
    }
    // 保存评论
    saveNewsReply = async () => {
        let { iptValue } = this.state;
        if (!iptValue) {
            Toast.fail('请输入内容！', .5);
            return
        };
        if(iptValue.length>2000){
            Toast.fail('评论最多2000个字符！', .5);
            return
        }
        let tokenFromUrl = this.searchObj.token;
        let params = { content: iptValue, token: tokenFromUrl, relateId: this.state.newsData.relateId};
        // console.log('发布评论',params);
        const saveNewsReply = await apis.project.saveNewsReply(params);
        if (saveNewsReply) {
            Toast.success('评论已发布', 1);
            this.setState({ iptValue: '' })
            this.pageNewsReply(1);
        }
        document.getElementById('phone-news-content').addEventListener('scroll', this.onRootScroll);
    }
    //收藏新闻 status-1收藏 0-取消收藏
    newsFavor = async (status)=>{
        let tokenFromUrl = this.searchObj.token;
        if(!tokenFromUrl){
            Toast.fail('请先登录',.6);
            setTimeout(() => {
                window.open('scooper://openLogin')
            }, 1000);
            return 
        }
        let params = { status, token: tokenFromUrl, relateId: this.state.newsData.relateId};
        const newsFavor = await apis.project.newsFavor(params);
        if(newsFavor&&status==1){
            this.setState({doCollectSuccess: true});
            Toast.success('已收藏',.5);
        }else if(newsFavor&&status==0){
            this.setState({doCollectSuccess: false});
            Toast.success('已取消收藏',.5);
        }
       
    }
    onIptFocus = () => {
        if(!this.searchObj.token){
            Toast.fail('请先登录',.6);
            document.getElementById('relay-ipt').blur();
            setTimeout(() => {
                window.open('scooper://openLogin')
            }, 1000);
            return 
        }
        this.setState({ iptFocus: true })
    }
    // 发评论ipt
    onIptChange = (iptValue) => {
        this.setState({ iptValue });
    }
    onIptBlur = () => {
        setTimeout(() => {
            this.setState({ iptFocus: false })
        }, 500);
    }
   
    shareWxHyFunc = () => {
        const { newsData, firstText } = this.state;
         window.open(
            `scooper://WxShare?type=webpage&title=${encodeURIComponent(newsData.newsTitle)}&description=${encodeURIComponent(firstText)}&scene=0&url=${window.location.origin + '/detail-web/' + this.props.match.params.classify + '/' + this.props.match.params.id}`
         )
    }
    shareWxPyqFunc = () => { 
        const { newsData, firstText } = this.state;
        window.open(`scooper://WxShare?type=webpage&title=${encodeURIComponent(newsData.newsTitle)}&description=${encodeURIComponent(firstText)}&scene=1&url=${window.location.origin + '/detail-web/' + this.props.match.params.classify + '/' + this.props.match.params.id}`)
    }
    shareDDPyqFunc = () => { 
        const { newsData, firstText } = this.state;
        window.open(`
        scooper://DDShare?title=${encodeURIComponent(newsData.newsTitle)}&description=${encodeURIComponent(firstText)}&url=${window.location.origin + '/detail-web/' + this.props.match.params.classify + '/' + this.props.match.params.id}
        `
        )
    }
    showShareActionSheet = () => {
        ActionSheet.showShareActionSheetWithOptions({
            options: [
                {icon: <img src={shareWxHy} alt={'微信好友'} style={{ width: 46 }} onClick={this.shareWxHyFunc} />,
                title: '微信好友',},
                {icon: <img src={shareWxPyq} alt={'朋友圈'} style={{ width: 46 }} onClick={this.shareWxPyqFunc} />,
                title: '朋友圈'},
                {icon: <img src={shareDD} alt={'钉钉'} style={{ width: 46 }}  onClick={this.shareDDPyqFunc}/>,
                title: '钉钉'}
            ],
        },
        () => {
            return new Promise((resolve) => {
                setTimeout(resolve, 10);
            });
        });
    }
    getNowDate = () => {
        let date = new Date();
        let year = date.getFullYear();
        let mounth = (date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1);
        let day = (date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate());
        return String(year) + '-' + String(mounth) + '-' + String(day)
    }
    goBack = () => {
        window.open(`scooper://closeAllPage`);
        sessionStorage.setItem('isGoBak', true);
    }

    // 切换滚动的位置（点击评论 - 在评论列表与上次阅读位置切换）
    scrollToggle = () => {
        let { newContentLoading, aggregationListLoading, relateListLoading, scrollToRelay,  lastPosition } = this.state;
        if(newContentLoading||aggregationListLoading||relateListLoading){
            return
        }
        const scrollEle = document.getElementById('phone-news-content');
        if(scrollToRelay){ // 如果下次是滚动到评论，就记录现在的位置
            let lastPosition = scrollEle.scrollTop
            this.setState({ lastPosition });
        }
        let scrollToPx = scrollToRelay?document.getElementById('comment-list-wrapper').offsetTop: lastPosition;
        document.getElementById('phone-news-content').scrollTo({top: scrollToPx, behavior:'smooth'});
        this.setState((state)=>{return {scrollToRelay: !state.scrollToRelay }});
    }
    // 返回新闻列表
    backToNewsList=()=>{
        this.props.history.go(-1);
    }
    render() {
        let { shouldLeftMenuFixed, newsData, keywordsList, relyList, hasNextPage, relateList=[], aggregationList=[],
            iptFocus, iptValue, total, newContentLoading, aggregationListLoading, relateListLoading
             } = this.state;
         let loadingAll = newContentLoading||aggregationListLoading||relateListLoading;
         let { isPhone, isSharePage } = this.props
        //  console.log(newContentLoading,aggregationListLoading, relateListLoading, newContentLoading&&aggregationListLoading&&relateListLoading);
        return (
            // <div className={`page-content news-detail-page-wrapper`} id={`${1 ? 'news-detail-page-wrapper-shall' : 'news-detail-page-wrapper-not-shall'}`}>
            <div className={`page-content news-detail-page-wrapper ${isPhone&&!this.isH5Shell?'phone-noh5':''}`} id={this.isH5Shell ? 'news-detail-page-wrapper-shall' : 'news-detail-page-wrapper-not-shall'}>
                <div className="content-center top-fixed clearfix content-center-new-content">
                    <div className='left-content-wrapper' id='left-content-wrapper'>
                        <div className={`${shouldLeftMenuFixed ? 'menu-left-fixed' : ''}`}>
                            <Link to={HOME_PAGE} className='title'></Link>
                            <Share title={newsData.newsTitle} url={this.props.location.pathname} />
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className={`news-detail-page`}>
                            <div className="left-mod" id='phone-news-content'>
                                {
                                    isPhone&&!this.isH5Shell&&<div className="common-header-lback-r">
                                        <div className="dark_header-back-wrapper" onClick={this.backToNewsList}>
                                            <span className='header-back-ico'></span>
                                        </div>
                                    </div>
                                }
                                <div className="article-wrapper">
                                    {/* <Spin wrapperClassName='news-content-spin' spinning={newContentLoading} indicator={<Icon type="loading" style={{ fontSize: 24, color: '#FF842C' }} spin />}>  */}
                                        <Article newsData={newsData} /> 
                                    {/* </Spin> */}
                                    {keywordsList.length>0&&!newContentLoading&&<div className="keyworks-list-wrapper">
                                        {
                                            keywordsList.map((item, i) => (
                                                <span className="keyword-item" key={`keyword-${i}`}><i className='img'></i>{item}</span>
                                            ))
                                        }
                                    </div>}
                                </div>                             
                                {relateList.length > 0 && !newContentLoading && <div className="news-list-wrapper releted-list-wrapper">
                                    <div className='divide'></div>
                                    <h3>聚合报道</h3>
                                    <NewsList classifyKey={this.props.match.params.classify} fnewsList={relateList} />
                                </div>}
                             
                                {aggregationList.length > 0 && !newContentLoading &&  <div className="news-list-wrapper">
                                    <div className='divide'></div>
                                    <h3>相关事件</h3>
                                    <NewsList classifyKey={this.props.match.params.classify} fnewsList={aggregationList} />
                                </div>}
                                {/* 评论列表 */}
                                <div className='comment-list-wrapper' id='comment-list-wrapper'>
                                    {!newContentLoading &&<h5 className='comment-title'>
                                        {`${total||'0'}条评论`}
                                    </h5>}
                                    <div className='comment-list'>
                                        {(relyList||[]).map((item,index)=>(
                                            <div className='comment-item' key={'comment'+index}>
                                                <span className='item-avaror'>
                                                    <img src={(item.account||{}).avatar} alt=""/>
                                                </span>
                                                <div className='comment-item-cb'>
                                                    <div className='cb-head'><span className='name'>{(item.account||{}).nickname}</span></div>
                                                    <div className='cb-txt'>{item.content}</div>
                                                    <div className='cb-footer'><span className='time'>{item.gmtCreate}</span></div>
                                                </div>
                                            </div>
                                        ))}
                                        {hasNextPage &&!newContentLoading && <div className='load-more-btn'><Spin spinning={true}/></div>}
                                        {!hasNextPage&&!newContentLoading &&<div className='no-more-text'>没有更多</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="content-inner-right-wrapper right-mod">
                                <div className='newest-news-box'>
                                    <h2 className='common-news-title'><span className='main-text'>最新快讯</span><span className='sub-text'>NEWS</span></h2>
                                    <NewestNewsList classifyKey={this.props.match.params.classify} list={this.state.newestNewsList || []} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 新闻评论 */}
                <div className={`pub-comment-wrapper ${iptFocus ? 'pub-comment-ipt-focus-wrapper' : ''}`}>
                    <TextareaItem
                        id='relay-ipt'
                        onFocus={this.onIptFocus}
                        onBlur={this.onIptBlur}
                        className={`pub-comment-ipt ${iptFocus ? 'focus-input' : ''}`}
                        value={iptValue}
                        type="text"
                        onChange={this.onIptChange}
                        placeholder={`${this.searchObj.token?'写评论':'请先登录后发表评论'}`}
                        rows={iptFocus ? 3 : 1}
                    />
                    {iptFocus && <span className='pub-comment-btn' onClick={this.saveNewsReply}>发布</span>}
                    {!iptFocus && <div className="no-focus-right-hand">
                                        <i className='look-more-comment' onClick={this.scrollToggle}>
                                            <Badge className='commont-num' text={total} overflowCount={999}/>
                                        </i>
                                        <i className={`collect-btn-wrapper ${this.state.doCollectSuccess?'had-collect':''}`} onClick={()=>{this.newsFavor(this.state.doCollectSuccess==1?0:1)}}></i>
                                        <i className='share-btn-wrapper' onClick={this.showShareActionSheet}></i>
                                    </div>}

                </div>
            </div>
        );
    }
}

export default NewsContent;