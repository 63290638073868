/*
 * @Author: hexingxing
 * @Date: 2020-02-05 11:07:14
 * @LastEditTime: 2020-05-08 17:42:30
 * @LastEditors: Please set LastEditors
 * @Description: 24小时速报
 * @FilePath: \react-app\src\view\index\index.js
 */

import React, {PureComponent} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
// import $ from 'jquery';
import QRCode from 'qrcode';
import wbImg from '../image/weibo.png'
import wxImg from '../image/wechat.png'
@connect(
    state=>state.news,
)
@withRouter
class Share extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            QRCode:'',
            QRCodeShow:false,          
        }
    }
    componentDidMount=()=>{
        QRCode.toDataURL(`${window.location.origin}${this.props.url}`)
        .then(url => {
            this.setState({QRCode:url})
        })
        .catch(err => {
            console.error(err)
        })
    }
    showQrCode=()=>{
        this.setState({QRCodeShow:true})
    }
    hideQrCode=()=>{
        this.setState({QRCodeShow:false})
    }
    render() {
        let path = this.props.match.path;
        return (
            <div className='share-content'>
                <div className='share-item'>
                    <a  href={`http://service.weibo.com/share/share.php?appkey=&title=${this.props.title}&url=http://${window.location.origin}${this.props.url}`} target="_blank">                        <img className='share-img wb-img' src={wbImg} alt=""/>
                        微博
                    </a>
                </div>
                <div className='share-item' onMouseEnter={this.showQrCode} onMouseLeave={this.hideQrCode}>                    
                    <img src={wxImg} alt="" className='share-img wx-img'/>
                        微信
                    {this.state.QRCodeShow&&
                    <img className='wx-qrcode' src={this.state.QRCode} alt=""/>
                    }
                </div>
                
            </div>            
        );
    }
}

export default Share;