/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditTime : 2020-02-05 11:45:42
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \react-app\src\index.js
 */

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'; 

import moment from 'moment';
import 'moment/locale/zh-cn';

import store from "./store";
import AppRouter from './router/index';

import './less/normalize.less';
import './less/main.less';
import './less/common-class.less';

import * as serviceWorker from './serviceWorker';
moment.locale('zh-cn');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
// Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


