/*
 * @Author: your name
 * @Date: 2021-06-03 11:01:12
 * @LastEditTime: 2021-06-03 16:34:59
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \er-subao-web\src\store\index.js
 */
/**
 * @file store配置
 * @author XXX
 * @date 2019/10/10
 * @version:v1.1.0
 */

import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk'; //异步使用
import reducers from "../reducer";

const reduxDevTools = window.devToolsExtension ? window.devToolsExtension() : f => f;
const store = createStore(reducers, compose(
    applyMiddleware(thunk),
    reduxDevTools
));

export default store;