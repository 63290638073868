/*
 * @Author: hexingxing
 * @Date: 2020-02-05 11:07:14
 * @LastEditTime: 2021-06-03 15:01:25
 * @LastEditors: Please set LastEditors
 * @Description: 速报列表 标题，配图（可选），出处，发布时间
 * @FilePath: \react-app\src\view\index\index.js
 */

import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
// import {apis} from "../util/apis";
import NewsItem from './news-item';
// import { PullToRefresh } from 'antd-mobile';

import {connect} from "react-redux";
import { pageNewsList } from '../reducer/news';
// 列表数据

@connect(
    state=>state.news,
    {pageNewsList}
)
@withRouter
class NewsList extends Component {
    constructor(props){
        super(props);
       
        this.state = {
        };
    }

    render() {
        const { fnewsList } = this.props;
        return (
            <div className={`news-list clearfix ${this.props.isTop?'news-list-top':''}`}>
                {(fnewsList||[]).map((item,key)=>{
                    return <NewsItem isSharePage={this.props.isSharePage} key={key} newsData={item} classify={this.props.classifyKey}/>
                })}
            </div>
        );
    }
}

export default NewsList;