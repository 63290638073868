/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \react-app\src\Main.js
 */

import React, {PureComponent} from "react";
import {connect} from "react-redux";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
// import Footer from './component/footer';
import {ImReportIndexRouter} from './router/page-route';
import 'antd-mobile/dist/antd-mobile.css'
@connect(
    state=>state.news
)
class NavPage extends PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const path = (this.props.match||{}).url;
        return (
            <ConfigProvider locale={zhCN}>
                <ImReportIndexRouter path={`${path}`} cm={this.props.currentMenu}/>
            </ConfigProvider>
        );
    }
}

export default NavPage;