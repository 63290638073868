/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditTime: 2020-07-13 19:47:10
 * @LastEditors: hexingxing
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\util\apis.js
 */

import getServices from "./axios-request";

const projectPrefix = '/api/v1/';    //项目后台接口前缀(/subao-api/弃用)
const core= '/scooper-core-rest/data/';
const ms = '/api/ms/v1/'

export let apis = {

    //项目
    auth: {
        captcha: core + "login/captcha"
    },

    login: getServices(`${projectPrefix}`, () => localStorage.getItem('token'), {
        // 获取工贸企业接入分布
        login: { url: 'login/doLogin', type: 'post' },
        // logout: { url: 'login/doLoginOut', type: 'get' },
        getUserInfo:{url:'/login/tokenLogin',type:'post'},
        phoneLogin:{url:'/login/phoneLogin',type:'post'}, // 手机号验证码登录

    }),
    project: getServices(`${projectPrefix}data`, () => localStorage.getItem('token'), {
        listNewsClassify: {url:'/newsClassify/listNewsClassify',type:'get'}, // 速报分类
        pageNewsList: {url:'/news/page',type:'post'}, // 新闻列表
        queryRelate: {url:'/news/queryRelate',type:'post'}, // 聚合报道
        pageAggregation: {url:'/news/pageAggregation',type:'post'}, // 相关事件
        saveNewsReply: {url:'/newsReply/saveNewsReply',type:'post'}, // 评论欣新闻
        pageNewsReply: {url:'/newsReply/pageNewsReply',type:'post'}, // 新闻评论列表
        newsFavor: {url:'/newsGoods/favor',type:'post'}, // 新闻-收藏
        
        listLatest: {url:'/news/listLatest',type:'post'}, // 最新速报
        getNewsContent: {url:'/newsContent/getNewsDetail',type:'post'}, // 新闻内容
        count: {url:'/viewCount/count',type:'post'}, // 点击详情-统计点击率
        pageTop: {url:'/news/pageTop',type:'post'}, // 置顶

        isInCircle:{url:'/accRCircle/isInCircle',type:'post'},
        getCircle:{url:'/circle/getCircle',type:'post'},

        savePost:{url:'/post/savePost',type:'post'},// 保存帖子
        pagePost:{url:'/post/pagePost',type:'post'},// 帖子列表
        getPost:{url:'/post/getPost',type:'post'},// 获取单个帖子

        getPostTypeList:{url:'/postType/list',type:'post'},// 获取帖子分类列表
        savePostReply:{url:'/postReply/savePostReply',type:'post'},// 保存跟帖
        pagePostReply:{url:'/postReply/pagePostReply',type:'post'},// 跟帖列表
        updateCircle:{url:'/circle/updateCircle',type:'post'}, // 跟新圈子配置管理
        memberManage:{url:'/circle/memberManage',type:'post'}, // 圈子成员管理 列表
        approve:{url:'/accRCircle/approve',type:'post'}, // 圈子成员管理 同意/拒绝用户申请
        upload:{url:'/circle/upload',type:'post'}, // 上传圈子头像
        pageRelatedPost:{url:'/related/pagePost',type:'post'}, // 与我相关-我发表的/我跟帖的帖子
        pageRelatedReply:{url:'/related/pageReply',type:'post'}, // 与我相关-回复我的子
        setMsgRead:{url:'/baoMsg/setRead',type:'post'}, // 设置消息已读
        canEnterCircle:{url:'/accRCircle/enterCircle',type:'post'}, // 是否可以进入圈子
        delMember:{url:'/accRCircle/delMember',type:'post'}, // 删除圈子人员
        setAccRole:{url:'/accRCircle/setAccRole',type:'post'}, // 设置身份
        
        // 消息
        pageBaoMsg:{url:'/baoMsg/pageBaoMsg',type:'post'}, // 圈子成员管理 同意/拒绝用户申请        
        isCircleManager:{url:'/accRCircle/isCircleManager',type:'post'},// 是否是圈子管理员
        setTop:{url:'/post/setTop',type:'post'},// 置顶

        videoList:{url:'/video/list',type:'post'},// 直播列表
        setVideoTop:{url:'/video/setTop',type:'post'},
        viewVideoTime:{url:'/video/view',type:'post'}, //统计视频观看次数

        sendSms:{url:'/sms/sendSms',type:'post'}, // 发送验证码      
        intoCircle:{url:'/accRCircle/intoCircle',type:'post'}, // 加入圈子      
        listArea:{url:'/area/listArea',type:'post'}, // 城市列表
        
    }),
    ms: getServices(`${ms}data`, () => localStorage.getItem('token'), {
        getScienceArticle: {url:'/science/article/getScienceArticle',type:'post'}, // 科普文章详情
        // pageCity: {url: '/data/area/listArea', type: 'get'}, // 分页查询开通城市-根据城市名称获取城市信息

    }),
    projectUrls:{
        intoCircle: `${projectPrefix}data/accRCircle/intoCircle`
    }
}