/**
 * @file 存储用户信息 reducer
 * @author XXX
 * @date 2019/10/10
 * @version:v1.1.0
 */

const initState = {
    user : 'chen'
};

export function authReducer(state=initState,action) {
    switch (action.type){
        default: return state;
    }
}