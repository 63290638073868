/*
 * @Author: hexingxing
 * @Date: 2020-02-05 11:07:14
 * @LastEditTime: 2021-06-03 15:21:11
 * @LastEditors: Please set LastEditors
 * @Description: 24小时速报
 * @FilePath: \react-app\src\view\index\index.js
 */

import React, {PureComponent} from "react";
import { withRouter } from 'react-router-dom';
import { Timeline } from 'antd';
// import {Link,withRouter} from 'react-router-dom';
// import { Timeline, Button } from 'antd';
import NewestNewsItem from './newest-news-item';
import {connect} from "react-redux";

// 
@withRouter
@connect(
    state=>state.news
)
class NewestNewsList extends PureComponent {
    
    render() {
        // let path = this.props.match.path;
        return (
            <div className='news-list clearfix'>
               <Timeline>
                    {
                        ((this.props.list?this.props.list:this.props.latestnewsList)||[]).map((item,index)=>(
                                <Timeline.Item color="#EE8411" key={index}><NewestNewsItem newsData={item} classifyKey={this.props.classifyKey}/></Timeline.Item>
                            )
                        )
                    }
                </Timeline>
            </div>
        );
    }
}

export default NewestNewsList;