/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditTime: 2020-03-16 20:02:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\config\constants.js
 */
/**
 * @file 配置信息文件
 * @author XXX
 * @date 2018/10/10
 * @version:v1.1.0
 */

export const PROJECT_NAME = "应急头条";    //项目名
export const PROJECT_NAME_EN = "SUBAO";   //项目名英文

export const HOME_PAGE = "/ch/city";     // 登录之后默认跳转菜单 ,快讯下的地震
export const DEFAULT_MENU_SEL='city';  // 点击应急头条logo，跳转的地址以及标签

export const platUrl = 'http://192.168.106.94:8080';//平台地址
export const token = '';

