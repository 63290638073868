/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditTime: 2020-09-18 11:28:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\router\page-route.js
 */

import React from "react";
// import { browserHistory } from 'react-router';
import { Route, Switch  } from "react-router-dom";
import Loadable from "react-loadable";
import {Spin} from "antd";
import NewsContent from '../view/news-content'
import NewsContentWeb from '../view/news-content/news-content-web'
function Loading() {
    return (
        <Spin size="large" className="global-spin"/>
    ); 
}

// 速报首页 
const NewsImIndex = Loadable({
    loader: ()=>import('../view/news-im'),
    loading: Loading
});

// 新闻内容
// const NewsContent = Loadable({
//     loader: ()=>import('../view/news-content'),
//     loading: Loading
// });
// 新闻内容-分享版
// const NewsContentWeb = Loadable({
//     loader: ()=>import('../view/news-content/news-content-web'),
//     loading: Loading
// });

// 通用速报列表
const CommonReportContent = Loadable({
    loader: ()=>import('../view/news-list-page'),
    loading: Loading
});

// 快讯速报列表
const QuickReportContent = Loadable({
    loader: ()=>import('../view/quick-report-content'),
    loading: Loading
});
const EchartsTest = Loadable({
    loader: ()=>import('../view/charts/test'),
    loading: Loading
});
const Publish = Loadable({
    loader: ()=>import('../view/baobao/publish-topic'),
    loading: Loading
});
// 速报首页
const WrappedNormalLoginForm = Loadable({
    loader: ()=>import('../view/login'),
    loading: Loading
});

// 应急圈
const Baobao = Loadable({
    loader: ()=>import('../view/baobao'),
    loading: Loading
});
// 圈子 
const Circle = Loadable({
    loader: ()=>import('../view/baobao/circle'),
    loading: Loading
});
const CircleInfo = Loadable({
    loader: ()=>import('../view/baobao/circle-info'),
    loading: Loading
});
// 帖子内容
const TopicDetail = Loadable({
    loader: ()=>import('../view/baobao/topic-detail'),
    loading: Loading
});

// 帖子内容-分享版
const TopicDetailWeb = Loadable({
    loader: ()=>import('../view/baobao/topic-detail-web'),
    loading: Loading
});

const MobileVideo = Loadable({
    loader: () => import('../component/mobileVideo'),
    loading: Loading
});
const UserAgreement = Loadable({
    loader: () => import('../view/user-agreement'),
    loading: Loading
});
const PrivacyProtection = Loadable({
    loader: () => import('../view/privacy-protection'),
    loading: Loading
});
const LiveVideo = Loadable({
    loader: () => import('../view/live-video'),
    loading: Loading
});
const CircleManage = Loadable({
    loader: () => import('../view/baobao/circle-manage'),
    loading: Loading
});
const MyMsg = Loadable({
    loader: () => import('../view/baobao/my-msg'),
    loading: Loading
});
const Demo = Loadable({
    loader: () => import('../view/baobao/demo'),
    loading: Loading
});
const ReleatedToMe = Loadable({
    loader: () => import('../view/baobao/releated-to-me'),
    loading: Loading
});
const MemberManage = Loadable({
    loader: () => import('../view/baobao/member-manage'),
    loading: Loading
});
const DownLoad = Loadable({
    loader: () => import('../view/download'),
    loading: Loading
});
// 加入圈子
const JoinCircleWeb = Loadable({
    loader: () => import('../view/join-circle-web'),
    loading: Loading
});

// 单个人员的设置
const MemberSetting = Loadable({
    loader: () => import('../view/baobao/member-setting'),
    loading: Loading
});

// 科普文章详情
// ../view/science-article/science-article-detail
const ScienceArticleDetail = Loadable({
    loader: () => import('../view/science-article/science-article-detail'),
    loading: Loading
});

export function ImReportIndexRouter(props) { 
    return ( 
        <React.Fragment>
            {/* <Router> */} 
                {/* 速报 */}
                {/* <Route path={`/charts`} render={() =><EchartsTest  {...props} /> } /> */}
                <Switch>
                <Route path={`/login`} component={WrappedNormalLoginForm} />
                <Route path={`/ch`} component={NewsImIndex} />
                {/* 新闻详情 */}
                <Route path={`/detail/:classify/:id`} render={() =><NewsContent {...props} /> } />
                {/* 分享版详情 */}
                <Route path={`/detail-web/:classify/:id`} render={() =><NewsContentWeb />} />
                
                <Route path={`/science-article/:id`} render={() =><ScienceArticleDetail /> } />
                
                <Route path={`/science-article-web/:id`} render={() =><ScienceArticleDetail isSharePage={true}/> } />
                
                <Route path={`/publish`} render={() =><Publish  />} />
                <Route path={`/baobao`} render={() =><Baobao  />} />
                <Route path={`/circle`} render={() =><Circle  />} />
                <Route path={`/msg`} render={() =><MyMsg  />} />
                <Route path={`/circle-manage`} render={() =><CircleManage  />} />
                <Route path={`/member-manage`} render={() =><MemberManage  />} />
                <Route path={`/new-member-manage`} render={() =><MemberManage  />} />
                <Route path={`/member-setting`} render={() =><MemberSetting  />} />
                <Route path={`/circle-info`} render={() =><CircleInfo  />} />
                <Route path={`/releated-to-me`} render={() =><ReleatedToMe  />} />
                <Route exact path={`/topic-detail/:topicId`} render={() =><TopicDetail  />} />
                <Route path={`/topic-detail`} render={() =><TopicDetail  />} /> {/* 可从search传 */}
                <Route path={`/topic-detail-web/:topicId`} render={() =><TopicDetailWeb  />} />
                <Route path={`/topic-detail-web`} render={() =><TopicDetailWeb  />} />
                <Route path={`/download`} render={() =><DownLoad  />} />

                <Route path={`/video`} component={MobileVideo} />
                
                <Route path={`/live-video`} render={() =><LiveVideo  />} />
                <Route path={`/test`} render={() =><Demo  />} />
                {/* 网页端加入圈子 */}
                <Route path={`/join-circle`} render={() =><JoinCircleWeb  />} />
                
                {/* 使用权益 */}
                <Route path={`/user_agreement`} render={() =><UserAgreement  />} />
                <Route path={`/privacy_protection`} render={() =><PrivacyProtection  />} />
            {/* </Router> */}</Switch>
        </React.Fragment>
    )
};

// 一级菜单速报的左侧二级菜单对应的路由
export function NewsImRouter(props) {
    return (
        <React.Fragment>
            {/* <Router> */}
                <Route exact path={`/ch/*`} render={() =><CommonReportContent  {...props} /> }/>
            {/* </Router> */}
        </React.Fragment>
    )
};