/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditTime: 2021-06-03 15:22:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\router\index.js
 */

import React, {Component,Fragment} from 'react';
import { withRouter } from 'react-router-dom';
// import {Route, withRouter} from 'react-router-dom';
// import {ROUTER_CFG} from "../config/config";
import {connect} from "react-redux";
import Main from "../Main";
import {HOME_PAGE,} from '../config/constants';
import { setIsPhone } from '../reducer/news';

// const {NEWS_LIST, NEWS_DETAIL, NEWS_CONTENT_PAGE} = ROUTER_CFG;

@withRouter
@connect(
    state=>state.news,
    { setIsPhone }
)
class AppRouter extends Component { 

    // constructor(props){
    //     super(props);
    // }

    componentDidMount(){
        if(this.props.location.pathname === '/'){
            // this.props.history.push(HOME_PAGE);
            window.location.pathname=HOME_PAGE;
        }
        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) { //移动端
            //console.log('手机端')
            document.body.className='phone';
            this.props.setIsPhone(true);
        }else{
            //console.log('web端');
            document.body.className='web';
            this.props.setIsPhone(false);
        }
    }
    render() {
        return (
            <Fragment>
                <Main />
            </Fragment>
        );
    }
}

export default AppRouter;