/**
 * @file 数据请求时loading reducer
 * @author XXX
 * @date 2019/4/10
 * @version:v1.1.0
 */

const CHANG_LOADING = 'CHANG_LOADING';

const initState = {
    loading:false
};

export function loadingReducer(state=initState, action){
    switch (action.type){
        case CHANG_LOADING: return {...state, loading: action.loading};

        default: return state;
    }
}

export function changeLoading(loading) {
    return {
        type:CHANG_LOADING,
        loading:loading
    }
}