/*
 * @Author: hexingxing
 * @Date: 2020-02-05 11:07:14
 * @LastEditTime: 2021-06-03 14:57:11
 * @LastEditors: Please set LastEditors
 * @Description: 新闻内容页
 * @FilePath: \react-app\src\view\index\index.js
 */


import React, {PureComponent} from "react";
import {withRouter} from 'react-router-dom';
// import RcViewer from 'rc-viewer'

// import EchartsTest from '../charts/test.js'; 
// import convert from 'htmr';
// const getComponentsLib = require("html-to-react-components");
@withRouter
class Article extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            shareBoxFixed: true,
            articleBoxLeft:0, // 文章距离窗口左侧的距离
            articleBoxWidth:0, // 文章宽度
            articleBoxHeight: 0, // 文章高度
        }
    }
    componentDidMount=()=>{       
        this.setShareBoxStyle();
        // this.setShareBoxClass();
        // document.getElementById('root').addEventListener('scroll', this.setShareBoxClass); 
    }
    setShareBoxStyle=()=>{
        this.setState({
            articleBoxLeft: document.getElementById('article-box').getBoundingClientRect().left,
            articleBoxWidth: document.getElementById('article-box').offsetWidth,
            articleBoxHeight: document.getElementById('article-box').offsetHeight,
        })
    }

    
    // setShareBoxClass=()=>{
    //     const shareShowEleToptoW = document.getElementById('share-show').getBoundingClientRect().top;
    //     const wHeight = document.documentElement.clientHeight;
    //     // 分享盒子出现在可视区了
    //     if(shareShowEleToptoW<=wHeight){
    //         this.setState({
    //             shareBoxFixed:false,
    //         })
    //     }else{
    //         this.setState({
    //             shareBoxFixed:true,
    //         })
    //     }
    // }

    componentWillUnmount=()=>{
        // document.getElementById('root').removeEventListener('scroll', this.setShareBoxClass);
    }
    render() {
        let {newsData} = this.props;
        if(this.props.newsData.newsTitle){document.title = `${this.props.newsData.newsTitle}`}
        let c = newsData.newsContent||''; // [p  style="text-align:  center;"][/p]
        // let c = '[p  style="text-align:  center;"]1007[/p]';
        var as = c.replace(/\[p([^\]]*)\]/g,function(a,b){
            return `<p ${b}>`;
        }).replace(/\[\/p\]/g,function(a,b){
            return '</p>';
        }).replace(/\[img\]([^\[]+)/g,function(a,b){ // [img]image/news/2020/05/13/c1ceb4ce-9524-11ea-b651-00163e158638.jpeg[/img]
            return `<div class='pic-wrapper'><img src='${/^http/.test(b)?b:'/'+b}'/></div>` 
        }).replace(/\[\/img\]/g,function(a,b){
            return '';
        })
        .replace(/\[table([^\]]*)\]/g,function(a,b){ // [table  align="center"  width="750"  border="1"  cellspacing="0"  cellpadding="0"][/table] 
            return `<table ${b}>`
        }).replace(/\[\/table\]/g,function(a,b){
            return '</table>';
        })
        .replace(/\[tr\]/g,function(a,b){ //[tr][/tr]
            return `<tr>`
        }).replace(/\[\/tr\]/g,function(a,b){
            return '</tr>';
        })
        .replace(/\[td([^\]]*)\]/g,function(a,b){
            return `<td ${b}>`;
        }).replace(/\[\/td\]/g,function(a,b){
            return '</td>';
        })
        .replace(/\[strong\]/g,function(a,b){ //[tr][/tr]
            return `<strong>`
        }).replace(/\[\/strong\]/g,function(a,b){
            return '</strong>';
        })
        .replace(/\[video\]([^\[]+)/g,function(a,b){
            return `<div class='video-wrapper'><video   src='${b}' controls  width="300px"/></div>`
        }).replace(/\[\/video\]/g,function(a,b){
            return '';
        });
        const options = {
            toolbar: {
                show: false
            }
        };

        // <video src="路径" controls  autoplay width="300px"></video>
        return (
            <div className='article-box' id='article-box'>
                <h1 className='article-title'>{newsData.newsTitle}</h1>
                <div className='article-sub'>
                    <span>{newsData.newsSource}</span>
                    <span>{newsData.newsTime}</span>
                </div>
                <div className="article-content">
                {/* <RcViewer options={options} ref='viewer'> */}
                    <div dangerouslySetInnerHTML={{__html:  as}}>
                    </div>   
                {/* </RcViewer> */}
                    {/* {newsData.newsContent} */}
                   
                    {
                       
                    }

                    {/* 
                   <div className='pic-wrapper'>
                        <img src={aImage} alt=""/>
                    </div>                  
                    <p>部分网友在得知此消息后大呼“干得漂亮”，美国仗着自己有技术优势肆意妄为，今后如果还不消停，我们也有了应对办法，毕竟这可是全球四成的份额，就算美国想搞事情，美国企业也不敢配合，大家都是出来挣钱的，若执意撕破脸，那就看看到底谁离不开谁！</p>
                    */}
                    {/* 分享至 */}
                    {/* <i id='share-show'></i>
                    <div className={`article-share-box ${shareBoxFixed?'share-fixed':''}`} style={{left: `${articleBoxLeft}px`,width: `${articleBoxWidth}px`}}>
                        <span className='share-text'>分享至</span>
                        <span className='share-weixin share-img'><Icon type="wechat" style={{color:'#59E63F'}} /></span>
                        <span className='share-weibo share-img'><Icon type="weibo-circle" style={{color:'#FF5553'}} /></span>
                        <span></span>
                    </div> */}
                </div>

            </div>
        );
    }
}

export default Article;