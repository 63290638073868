/*
 * @Author: your name
 * @Date: 2020-02-05 10:23:08
 * @LastEditTime : 2020-02-09 15:23:13
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\reducer\index.js
 */
/**
 * @file reducer集合
 * @author XXX
 * @date 2019/10/10
 * @version:v1.1.0
 */

import {combineReducers} from 'redux';

import {eventHandleReducer as eventHandle} from './event-handle-reducer';
import {authReducer as auth} from './auth-reducer';
import {loadingReducer as loading} from './loading-reducer';
import {newsHandleReducer as news} from './news';
import {baobaoHandleReducer as baobao} from './baobao';

export default combineReducers({eventHandle,auth, loading, news, baobao})

