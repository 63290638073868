/*
 * @Author: your name
 * @Date: 2020-02-05 11:59:53
 * @LastEditTime: 2020-11-02 16:17:25
 * @LastEditors: Please set LastEditors
 * @Description: 推荐中的新闻 props：newsData一条新闻信息
 */
// 壳子里-都是点击整个item触发原生打开新页面
// 手机网页-是点击整个item打开新页面
// 电脑网页-是点击item的标题打开新页面

import React, { PureComponent, Fragment } from "react";
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
// import bgImage from '../image/login/login-code-logo.jpg';
import { commonFunc } from '../util/common';
import { setListScrollTop } from '../reducer/news';
import qs from 'qs';
@withRouter
@connect(
    state => state.news,
    { setListScrollTop }
)
class NewsItem extends PureComponent {
    constructor(props) {
        super(props);
        let searchObj = qs.parse(window.location.search.substr(1))||{}; 
        this.searchObj=searchObj;
        this.isH5Shell = searchObj.isH5Shell;
        this.state = {
            hasImg: true
        }
    }
    // 壳程序中的
    goDetail = () => {
        window.open(`scooper://openNewPage?url=${window.origin}/detail/${this.props.classify}/${this.props.newsData.id}?token=${this.searchObj.token?this.searchObj.token:undefined}&isH5Shell=${this.isH5Shell}`)
        // window.open(`scooper://openNewPage?url=${window.origin}/detail/${this.props.classify}/${this.props.newsData.id}`)
        // alert(`${window.origin}/detail/${this.props.classify}/${this.props.newsData.id}`)
        if (document.getElementById('app-pull-refresh')) {
            this.props.setListScrollTop(document.getElementById('app-pull-refresh').scrollTop)
        }
    }
    goDetailShare = () => {
        if (document.getElementById('app-pull-refresh')) {
            this.props.setListScrollTop(document.getElementById('app-pull-refresh').scrollTop)
        }
    }
    render() {
        let { newsData } = this.props;
        return (
            <Fragment>
                {/* web端 */}
                {!this.props.isPhone ? <div className='news-item clearfix'>
                    {newsData.imageUrl && <div className="left-img">
                        <Link to={`/detail/${this.props.classify}/${newsData.id}`}
                            target={`${this.props.isPhone ? '_self' : '_blank'}`}
                            // 没加域名就是源路径，有域名的都是image.e-subao.com上的（不用加）
                        ><img src={`${/^http/.test(newsData.imageUrl)?newsData.imageUrl:'/'+newsData.imageUrl}`} alt="" /></Link>
                    </div>}
                    <div className={`right-box ${newsData.imageUrl ? 'hasimg' : ''}`}>
                        <div className="right-box-inner">
                            <h2 className='title'>
                                <Link
                                    target={`${this.props.isPhone ? '_self' : '_blank'}`}
                                    to={`/detail/${this.props.classify}/${newsData.id}`} className='link'>{newsData.newsTitle}</Link>
                            </h2>
                            <div className='footer'>
                                <div className="left">
                                    <a target='_blank' href={newsData.sourceUrl}>{newsData.newsSource}</a>
                                </div>
                                <div className="right">{commonFunc.timeago(commonFunc.dateTimeStamp(newsData.newsTime))}</div>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    // 手机-壳子里的 - 点击触发原生事件
                    this.isH5Shell ?
                        <div onClick={this.goDetail}>
                            <div className='news-item clearfix'>
                                {newsData.imageUrl &&
                                    <div className="left-img"><img src={`${/^http/.test(newsData.imageUrl)?newsData.imageUrl:'/'+newsData.imageUrl}`} alt="" /></div>}
                                <div className={`right-box ${newsData.imageUrl ? 'hasimg' : ''}`}>
                                    <div className="right-box-inner">
                                        <h2 className='title'>
                                            {newsData.newsTitle}
                                        </h2>
                                        <div className='footer'>
                                            <div className="left">
                                                {newsData.newsSource}
                                            </div>
                                            <div className="right">
                                                {commonFunc.timeago(commonFunc.dateTimeStamp(newsData.newsTime))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        // 手机网页
                        !this.props.isSharePage ?
                            <Link to={`/detail/${this.props.classify}/${newsData.id}${this.props.location.search}`} 
                                // target={`_blank`}
                            >
                                <div >
                                    <div className='news-item clearfix'>
                                        {newsData.imageUrl &&
                                            <div className="left-img"><img src={`${/^http/.test(newsData.imageUrl)?newsData.imageUrl:'/'+newsData.imageUrl}`} alt="" /></div>}
                                        <div className={`right-box ${newsData.imageUrl ? 'hasimg' : ''}`}>
                                            <div className="right-box-inner">
                                                <h2 className='title'>
                                                    {newsData.newsTitle}
                                                </h2>
                                                <div className='footer'>
                                                    <div className="left">
                                                        {newsData.newsSource}
                                                    </div>
                                                    <div className="right">
                                                        {commonFunc.timeago(commonFunc.dateTimeStamp(newsData.newsTime))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            :
                            // 分享版
                            this.props.isSharePage && <Link to={`/detail-web/${this.props.classify}/${newsData.id}`} target={`${this.props.isPhone ? '_self' : '_blank'}`}>
                                <div onClick={this.goDetailShare}>
                                    <div className='news-item clearfix'>
                                        {newsData.imageUrl &&
                                            <div className="left-img"><img src={`${/^http/.test(newsData.imageUrl)?newsData.imageUrl:'/'+newsData.imageUrl}`} alt="" /></div>}
                                        <div className={`right-box ${newsData.imageUrl ? 'hasimg' : ''}`}>
                                            <div className="right-box-inner">
                                                <h2 className='title'>
                                                    {newsData.newsTitle}
                                                </h2>
                                                <div className='footer'>
                                                    <div className="left">
                                                        {newsData.newsSource}
                                                    </div>
                                                    <div className="right">
                                                        {commonFunc.timeago(commonFunc.dateTimeStamp(newsData.newsTime))}
                                                        {/* {} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                }
            </Fragment>
        );
    }
}
export default NewsItem;