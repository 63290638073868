/*
 * @Author: your name
 * @Date: 2020-02-09 15:19:34
 * @LastEditTime: 2021-06-03 15:03:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\reducer\news.js
 */
/**
 * @file 应急圈 reducer
 * @author hexingxing
 */
// import {apis} from "../util/apis";
// import { commonFunc } from '../util/common';
const TOPIC_LIST_CHANGE = 'topicListChange';  // 帖子列表
const TOPIC_LIST_PAGE_NUM_CHANGE = 'topicPageNumChange'; // 页码
const TOPIC_LIST_HAS_NEXT_PAGE_CHANGE = 'topicHasNextPageChange';// 是否有下一页
const TOPIC_LIST_SCROLLTOP_CHANGE = 'topicListScrollTop';// list的滚动条位置
const CIRCLE_NAME = 'circleName';
const initState = {
    topicList: [], // 帖子列表
    pageNum:1,
    hasNextPage: true,
    listScrollTop:0,
    circleName:'',
};

export function baobaoHandleReducer(state=initState,action) {
    switch (action.type){
        case TOPIC_LIST_CHANGE: 
            console.log(action)
            if(state.pageNum>1){
                return {...state, topicList : [...(state.topicList||[]),...(action.topicList||[])]};                
            }else{
                return {...state, topicList : action.topicList};
            }
        case TOPIC_LIST_PAGE_NUM_CHANGE: return {...state, pageNum: action.pageNum}
        case TOPIC_LIST_HAS_NEXT_PAGE_CHANGE: return {...state, hasNextPage: action.hasNextPage}
        case TOPIC_LIST_SCROLLTOP_CHANGE: return {...state, listScrollTop: action.listScrollTop}
        case CIRCLE_NAME: return {...state, circleName: action.circleName}
        default: return state;
    }
}

/*********************action-start****************************/

// 改变帖子列表
export function setTopicListAction(list){
    return {type: TOPIC_LIST_CHANGE, topicList: list};
}
// 改变当前页码
export function setPageNumAction(pageNum){
    return {type: TOPIC_LIST_PAGE_NUM_CHANGE, pageNum: pageNum};
}
// 是否有下一页
export function setHasNextPageAction(hasNextPage){
    return {type: TOPIC_LIST_HAS_NEXT_PAGE_CHANGE, hasNextPage: hasNextPage};
}
// 滚动条位置
export function setListScrollTop(listScrollTop){
    return {type: TOPIC_LIST_SCROLLTOP_CHANGE, listScrollTop: listScrollTop};
}
// 圈子名字
export function setCircleName(circleName){
    return {type: CIRCLE_NAME, circleName: circleName};
}

/*********************action-end****************************/