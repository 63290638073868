/*
 * @Author: your name
 * @Date: 2020-02-09 15:19:34
 * @LastEditTime: 2021-06-03 13:58:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \imrpt\src\reducer\news.js
 */
/**
 * @file 新闻 reducer
 * @author hexingxing
 */
import {apis} from "../util/apis";
import { commonFunc } from '../util/common';
const MENU_CHANGE = 'menuChange'; // 当前选中的menu
const NEWS_LIST_CHANGE = 'newsListChange'; // 新闻列表
const PAGE_NUM_CHANGE = 'pageNumChange'; // 页码
const HAS_NEXT_PAGE_CHANGE = 'hasNextPageChange';// 是否有下一页
const LATEST_LIST_CHANGE = 'latestListChange';// 最新新闻列表
const END_TIME_CHANGE = 'endTimeChange';// 上次查询新闻列表时间
const NEWS_TOP_CHANGE = 'newsTopChange';// 置顶新闻
const IS_PHONE_CHANGE = 'isPhoneChange';// 是移动端还是web
const LIST_SCROLLTOP_CHANGE = 'listScrollTop';// list的滚动条位置
const CITY_CODE_CHANGE = 'cityCodeChange';// 改变城市选择

const initState = {
    currentMenu: 'city', // 当前menu
    pageNum:1,
    newsList: [], // 新闻列表
    hasNextPage: true,
    latestnewsList:[],// 最新速报
    endTime:'', // 'yyyy/MM/dd hh:mm:ss',
    newsTop:[],// 置顶新闻
    isPhone:false,//是否在移动设备上
    listScrollTop:0,// 
    cityCode:'',
};

export function newsHandleReducer(state=initState,action) {
    switch (action.type){
        case MENU_CHANGE: return {...state, currentMenu: action.currentMenu};
        case NEWS_LIST_CHANGE: 
            if(state.pageNum>1){
                return {...state, newsList : [...(state.newsList||[]),...action.newsList]};                
            }else{
                return {...state, newsList : action.newsList};
            }
        case END_TIME_CHANGE: return {...state,endTime: action.endTime}
        case PAGE_NUM_CHANGE: return {...state, pageNum: action.pageNum}
        case HAS_NEXT_PAGE_CHANGE: return {...state, hasNextPage: action.hasNextPage}
        case LATEST_LIST_CHANGE: return {...state,latestnewsList: action.latestnewsList}
        case NEWS_TOP_CHANGE: return {...state, newsTop: action.newsTopList}
        case IS_PHONE_CHANGE: return {...state, isPhone: action.isPhone}
        case LIST_SCROLLTOP_CHANGE: return {...state, listScrollTop: action.listScrollTop}
        case CITY_CODE_CHANGE: return {...state, cityCode: action.cityCode}
        default: return state;
    }
}

/*********************action-start****************************/
// 改变menu - action
export function changeMenu(key) {
    return {type: MENU_CHANGE, currentMenu: key};
}
// 改变新闻列表
export function setNewsListAction(list){
    return {type: NEWS_LIST_CHANGE, newsList: list};
}
// 改变当前页码
export function setPageNumAction(pageNum){
    return {type: PAGE_NUM_CHANGE, pageNum: pageNum};
}
// 是否有下一页
export function setHasNextPageAction(hasNextPage){
    return {type: HAS_NEXT_PAGE_CHANGE, hasNextPage: hasNextPage};
}
// 改变新闻列表
export function setLatestListAction(list){
    return {type: LATEST_LIST_CHANGE, latestnewsList: list};
}
export function setEndTime(endTime){
    return {type: END_TIME_CHANGE, endTime: endTime};
}
export function setNewsTop(list){
    return {type: NEWS_TOP_CHANGE, newsTopList: list};
}
export function setIsPhone(isPhone){
    return {type: IS_PHONE_CHANGE, isPhone: isPhone};
}
export function setListScrollTop(listScrollTop){
    return {type: LIST_SCROLLTOP_CHANGE, listScrollTop: listScrollTop};
}
export function setCityCode(cityCode){
    return {type: CITY_CODE_CHANGE, cityCode: cityCode};
}

/*********************action-end****************************/


// 
/* 获取新闻列表 */
// isDown,是否下拉刷新
export function  pageNewsList( classifyKey, pageNum, endTime, isDown, scrollToTop, s, cityCode, areaCode) {
    return async dispatch => {
        dispatch(setPageNumAction(pageNum));
        let now = new Date();
        let startTime = commonFunc.getNowStartTime(now);
        
        if(s){
            dispatch(setNewsListAction([]));
        }
        if(cityCode){
            dispatch(setCityCode(cityCode));
        }
        // if(areaCode){ // 点击的是本地城市

        // }
        let params = {pageNum:pageNum||1, pageSize:20, classifyKey: classifyKey, 
        // goTime:pageNum===1?'':startTime,
         endTime:endTime
         };
         if(params.classifyKey==='city'){
            params.classifyKey='';
            params.areaCode=areaCode||'330100000000'; // 杭州是330100000000
         }
      
         if(isDown){// 下拉刷新时
            params = {pageNum:pageNum||1, pageSize:10, classifyKey: classifyKey, 
                goTime:endTime,
            };
         }
        const dataRes = await apis.project.pageNewsList(params) || {};
        dispatch(setNewsListAction(dataRes.list||[]));
    //    dispatch(setPageNumAction(dataRes.pageNum));
        dispatch(setHasNextPageAction(dataRes.hasNextPage));
        dispatch(setEndTime(startTime));
        if(scrollToTop){
            setTimeout(() => {
                commonFunc.appScrollToTop();
            }, 500);
        }
    }
}

/* 获取最新速报新闻列表 */
export function  listLatestNews() {
    return async dispatch => {      
       const dataRes = await apis.project.listLatest({pageSize: 10}) || [];
       dispatch(setLatestListAction(dataRes))    
    }
}

/* 获取置顶新闻 */
export function  pageTop( classifyKey ) {
    return async dispatch => {
        let params = { classifyKey: classifyKey };
        const dataRes = await apis.project.pageTop(params) || {};
        dispatch(setNewsTop(dataRes));
    }
}